<template>
  <div v-if="isZoomed" class="fullscreen">
    <o-button class="close-btn" icon-left="close" @click="isZoomed = false" />
    <img class="fullscreen-inner" :src="url" style="width: auto" />
  </div>
  <figure class="zoomed" style="margin: 0 0 2rem 0" @click="zoom">
    <img :src="url" style="width: auto; margin: 0 auto" />
  </figure>
</template>

<script>
export default {
  name: 'ZoomedImage',

  props: {
    imageUrl: {
      default: null,
      type: String,
    },
  },

  data: () => ({
    isZoomed: false,
  }),

  computed: {
    url() {
      if (this.imageUrl?.includes('googleusercontent')) {
        return this.imageUrl + '=s0'
      }
      return this.imageUrl
    },
  },

  methods: {
    zoom() {
      this.isZoomed = true
    },
  },

}
</script>

<style scoped lang="scss">
figure.zoomed {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: white;
  overflow-y: scroll;
}

.close-btn {
  top: 1rem;
  right: 1rem;
  position: fixed;
  z-index: 101;
}

.fullscreen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  overflow: scroll;
  overscroll-behavior: contain;
}

.fullscreen-inner {
  position: absolute;
  padding-top: 2rem;
  top: 2rem;
  @media (max-width: 768px) {
    top: 10rem;
  }
}
</style>
